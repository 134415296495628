import React from 'react';

import TeaserImage from './TeaserImage';

import * as styles from './TeaserImages.module.css';
import classnames from 'classnames';

function TeaserImages({ data }) {
  return (
    <section className={classnames(styles.TeaserImages, 'defaultWidth')}>
      {data.map(({ node }) => (
        <React.Fragment key={node.title}>
          {node.homepage ? (
            <a href={node.homepage} target="_blank">
              <TeaserImage data={node} />
            </a>
          ) : (
            <TeaserImage data={node} />
          )}
        </React.Fragment>
      ))}
    </section>
  );
}

export default TeaserImages;
