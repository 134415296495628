import * as React from 'react';
import { graphql } from 'gatsby';
import { Metadata } from '@mangoart/gatsby-ui/components/Metadata';
import Slider from '@mangoart/gatsby-ui/components/ezagrar/EZAgrarSlider';

import Slugger from '@mangoart/gatsby-ui/helper/Slugger';
import TeaserImages from '@mangoart/gatsby-ui/components/ezagrar/TeaserImages/TeaserImages';
import TeaserText from '@mangoart/gatsby-ui/components/ezagrar/TeaserText/TeaserText';
import TeaserBoxes from '@mangoart/gatsby-ui/components/ezagrar/TeaserBoxes/TeaserBoxes';
import DefaultLayout from '../layout/DefaultLayout';

const IndexPage = ({ data, location }) => {
  const { slides, siteData, brands, pageData, news } = data;
  const { siteMetadata } = siteData;
  const { metadata, opengraph } = pageData;
  const teaserImages = brands.edges.map(({ node }) => {
    return {
      node: {
        title: node.name,
        image: node.logo,
        alttext: node.name + ' Logo',
        homepage: node.homepage,
      },
    };
  });

  function transformDate(dateString) {
    // format dd.mm.yyy
    const parts = dateString.split('.');
    return new Date(parts[2], parseInt(parts[1]) - 1, parts[0]);
  }

  function sortByDate(entry1, entry2) {
    return transformDate(entry2.node.date).getTime() - transformDate(entry1.node.date).getTime();
  }

  function filterExpired(node) {
    if (node.node.expirationdate <= 0) {
      return node;
    }
    return transformDate(node.node.expirationdate).getTime() < new Date().getTime() ? null : node;
  }

  const teaserBoxes = news.edges
    .sort(sortByDate)
    .filter(filterExpired)
    .map(({ node }) => {
      return {
        node: {
          id: node.id,
          title: node.title,
          published: true,
          teaser: node.teaser,
          alttext: '',
          image: {
            ...node.teaserimage,
          },
          actionlabel: 'Weiterlesen',
          link: `/news/${Slugger.slug(node.title)}`,
        },
      };
    })
    .filter((item, index) => index < 4);
  return (
    <DefaultLayout>
      <Metadata
        data={metadata}
        opengraphData={opengraph}
        defaultTitle={siteMetadata.title}
        canonicalSiteUrl={siteMetadata.canonicalSiteUrl}
        path={location.pathname}
      />
      <Slider data={slides.edges} />
      <TeaserImages data={teaserImages} />
      <TeaserText
        data={{
          title: 'Landtechnik erleben',
          text:
            'Modernste Technik erleben Sie in beheizten Schauräumen, unterstützt mit neuester Medientechnik können wir auf Ihre speziellen Anliegen und Bedürfnisse eingehen und sie kompetent beraten. Selbstverständlich erfahren Sie auf unserem großen Testgelände direkt das Fahrgefühl und die Vorteile Ihres Wunschmodells.' +
            'Weiters bieten wir hier in modernsten Schulungsräumen ein umfangreiches Angebot an Schulungen Vergleichbares hat es in der Landtechnik bis heute nicht gegeben.',
          link: null,
          label: null,
        }}
      />
      <TeaserBoxes data={teaserBoxes} />
    </DefaultLayout>
  );
};

export default IndexPage;

export const IndexPageQuery = graphql`
  query IndexPageQuery {
    siteData: site {
      id
      siteMetadata {
        title
        canonicalSiteUrl
      }
    }
    pageData: cockpitPageImpressum {
      metadata {
        title
        metakeywords
        metadescription
      }
      opengraph {
        title
        description
        type
        image {
          id
          childImageSharp {
            gatsbyImageData(width: 1200, height: 630)
          }
        }
      }
    }
    news: allCockpitNews(filter: { published: { eq: true } }, sort: { fields: date, order: DESC }) {
      edges {
        node {
          id
          title
          teaser
          date
          expirationdate
          teaserimage {
            childImageSharp {
              gatsbyImageData(width: 400, aspectRatio: 2, transformOptions: { fit: COVER }, backgroundColor: "#fff")
            }
          }
          images {
            alttext
            image {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
    slides: allCockpitSlides(filter: { published: { eq: true } }, sort: { fields: order, order: ASC }) {
      edges {
        node {
          id
          title
          highlighted_line
          position
          text_color
          text_line_1
          text_line_2
          mobile_text_line_1
          mobile_text_line_2
          image {
            id
            childImageSharp {
              gatsbyImageData(width: 1600, backgroundColor: "#fff", quality: 80)
            }
          }
        }
      }
    }
    teaserboxes: allCockpitTeaserbox {
      edges {
        node {
          id
          title
          published
          teaser
          alttext
          image {
            id
            childImageSharp {
              gatsbyImageData(width: 300, aspectRatio: 1, transformOptions: { fit: CONTAIN }, backgroundColor: "#fff")
            }
          }
          actionlabel
        }
      }
    }
    brands: allCockpitMarken(sort: { fields: order, order: ASC }) {
      edges {
        node {
          name
          description
          homepage
          logo {
            id
            childImageSharp {
              gatsbyImageData(
                placeholder: NONE
                width: 300
                aspectRatio: 1.5
                transformOptions: { fit: CONTAIN }
                backgroundColor: "#fff"
              )
            }
          }
        }
      }
    }
  }
`;
