import React from 'react';

import * as styles from './TeaserImage.module.css';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

function TeaserImage({ data }) {
  const { image, alttext } = data;

  return (
    <div className={styles.TeaserImage}>
      <div className={styles.imageContainer}>
        <GatsbyImage style={{ height: 200, width: '100%' }} alt={alttext} image={getImage(image)} />
      </div>
    </div>
  );
}

export default TeaserImage;
